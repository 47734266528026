import React, { useState, useEffect, useContext } from "react";
import styled, { keyframes } from "styled-components";
import { Text } from "components/typography";
import { Context } from "utils/Context";

const fadeIn = keyframes`
 0% { opacity: 0; }
 100% {  opacity: 1; }
`;

const StyledSubBrand = styled(Text)`
  /* width: max-content; */
  max-width: 100%;
  margin: 0 auto;
  animation: ${fadeIn} 0.15s linear;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid transparent;
  height: max-content;
  text-align: center;

  ${({ activeItem }) =>
    activeItem && `color: #939598; border: 1px solid #939598;`}

  @media (max-width: 600px) {
    margin: 0;
    text-align: left;
  }
`;

const SubBrand = ({ subBrand }) => {
  const [activeItem, setActiveItem] = useState(false);
  const contextSubBrand = useContext(Context);

  useEffect(() => {
    if (contextSubBrand.selectedSubBrand.subBrand === subBrand.subBrand) {
      setActiveItem(true);
    } else {
      setActiveItem(false);
    }
  }, [contextSubBrand]);

  return (
    <Context.Consumer>
      {({ selectSubBrand }) => (
        <StyledSubBrand
          id="subBrand"
          onClick={() => selectSubBrand(subBrand)}
          activeItem={activeItem}
        >
          {subBrand.subBrand}
        </StyledSubBrand>
      )}
    </Context.Consumer>
  );
};

export default SubBrand;
