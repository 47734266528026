import styled, { css } from "styled-components";

const baseStyle = css`
  font-weight: 400;
  line-height: 2;
`;

export const Text = styled.p`
  ${baseStyle}

  font-size: 1.6rem;
`;

export const TextSmall = styled.p`
  ${baseStyle}

  font-size: 1.2rem;
`;
