import styled from "styled-components";

export const H1 = styled.h1`
  text-align: center;
  font-size: 3.2rem;
  margin: 0;
  margin-bottom: 8rem;
  font-weight: 400;

  @media (max-width: 600px) {
    font-size: 2.4rem;
    margin-bottom: 4rem;
  }
`;

export const H2 = styled.h2`
  font-size: 3rem;
  margin: 0;
`;
