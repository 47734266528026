import React, { useState } from "react";
import styled, { css } from "styled-components";
import Layout from "components/PageLayout";
import SEO from "components/Seo";
import { Section } from "components/layout";
import Brand from "components/Brand";
import SubBrandTable from "components/SubBrandTable";
import { H1 } from "components/typography";
import { Context } from "utils/Context";
import data from "content/products.json";

const Container = styled.div`
  ${({ isSelected }) =>
    isSelected &&
    css`
      display: flex;

      @media (max-width: 1100px) {
        flex-direction: column;
      }
    `}
`;

const BrandsContainer = styled.div`
  display: grid;
  grid-gap: 2rem;
  align-items: center;
  justify-content: center;
  grid-template-columns: minmax(10rem, 30rem);

  @media (max-width: 1100px) {
    margin: 0 0 2.4rem;
    justify-content: center;
    grid-gap: 3rem;
    grid-template-columns: auto;

    &::after {
      content: "";
      border-bottom: 1px solid #939598;
      width: 90vw;
      height: 1px;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      justify-content: left;
      margin-right: 2.4rem;
    `}
`;

const Products = () => {
  const [isSelected, setIsSelected] = useState(false);

  const checkIfBrandIsActive = () => {
    // eslint-disable-next-line no-undef
    const containsSubBrand = document.getElementById("subBrand");

    if (containsSubBrand) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  };

  return (
    <Context.Consumer>
      {({ selectedSubBrand }) => (
        <Layout>
          <SEO title="Products" />
          <Section>
            <Container isSelected={isSelected}>
              <div>
                {!isSelected && <H1>Choose brand to find suitable parts</H1>}
                <BrandsContainer isSelected={isSelected}>
                  {data.brands.map((brand) => (
                    <Brand
                      key={brand.brand}
                      data={brand}
                      checkIfBrandIsActive={checkIfBrandIsActive}
                    />
                  ))}
                </BrandsContainer>
              </div>

              {!!isSelected && (
                <SubBrandTable selectedSubBrand={selectedSubBrand} />
              )}
            </Container>
          </Section>
        </Layout>
      )}
    </Context.Consumer>
  );
};
export default Products;
