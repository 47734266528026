import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { H2 } from "components/typography";
import SubBrand from "components/SubBrand";

const fadeIn = keyframes`
from { opacity: 0; }
to {  opacity: 1; }
`;

const StyledBrandName = styled(H2)`
  cursor: pointer;
  text-align: center;
  width: max-content;
  margin: 0 auto;
  animation: ${fadeIn} 0.15s linear;
  transition: all 0.2s;

  &:hover {
    color: #939598;
  }

  ${({ isBrandSelected }) =>
    isBrandSelected &&
    `  margin: 0 auto 0.6rem;
`}

  @media (max-width: 900px) {
    margin-bottom: 1rem;
  }
`;

const SubBrandGrid = styled.div`
  display: grid;
  grid-gap: 1rem;
  /* max-width: 30rem; */

  @media (max-width: 1100px) {
    grid-template-columns: repeat(3, minmax(10px, 1fr));
    grid-gap: 2rem;
    max-width: 100%;
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, minmax(10px, 1fr));
  }
`;

const Brand = ({ data: { brand, subBrand }, checkIfBrandIsActive }) => {
  const [isBrandSelected, setIsBrandSelected] = useState(false);

  const handleClick = () => {
    setIsBrandSelected(!isBrandSelected);
  };

  useEffect(() => {
    checkIfBrandIsActive();
  }, [isBrandSelected]);

  return (
    <div>
      <StyledBrandName onClick={handleClick} isBrandSelected={isBrandSelected}>
        {brand}
      </StyledBrandName>

      <SubBrandGrid>
        {isBrandSelected &&
          subBrand.map((subBrandItem, i) => (
            <SubBrand
              key={subBrandItem.subBrand + i}
              id="subBrand"
              subBrand={subBrandItem}
            />
          ))}
      </SubBrandGrid>
    </div>
  );
};
export default Brand;
