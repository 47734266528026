import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  scroll-margin-top: 10rem;
`;

const Table = styled.table`
  border-spacing: 0;
  width: 60rem;
  th,
  td {
    padding: 0.8rem;
  }

  @media (max-width: 1100px) {
    width: 90vw;
    max-width: 100%;
  }
`;

const TBody = styled.tbody`
  font-size: 1.6rem;

  tr:nth-child(odd) {
    background-color: rgba(157, 157, 157, 0.4);
  }
  tr:nth-child(even) {
    background-color: rgba(220, 220, 220, 0.4);
  }

  &:not(:first-child) {
    text-align: center;
  }
`;

const THeader = styled.thead`
  th {
    font-size: 1.5rem;
  }
`;

const TableTitle = styled.p`
  font-size: 1.8rem;
  align-self: flex-start;
  max-width: 60rem;

  @media (max-width: 1100px) {
    max-width: 100%;
  }
`;

const THNumbers = styled.th`
  text-align: left;
`;

const TDNumbers = styled.td`
  text-align: left;
  max-width: max-content;
`;

const TDCode = styled.td`
  word-break: break-all;
  min-width: 9rem;
`;

const ErrorMessage = styled.p`
  margin-top: 0.8rem;
  padding: 2.4rem 0.4rem;
  font-size: 2.4rem;
  color: #611f24;
  background: #dd9299;
`;

const SubBrandTable = ({ selectedSubBrand: { subBrand, items } }) => {
  const tableHeadings = items && Object.keys(items[0]);

  return (
    <Container id="sub-brand">
      {!!subBrand && (
        <>
          <TableTitle>{subBrand}</TableTitle>
          {items ? (
            <Table>
              <THeader>
                <tr>
                  <THNumbers>Item</THNumbers>
                  {tableHeadings.map((heading, i) => (
                    <th key={heading + i}>{heading}</th>
                  ))}
                </tr>
              </THeader>
              <TBody>
                {items.map((subBrandItem, i) => (
                  <tr key={subBrandItem.Code + i}>
                    <TDNumbers>{i + 1}</TDNumbers>
                    <TDCode>{subBrandItem.Code}</TDCode>
                    <td>{subBrandItem.Description}</td>
                  </tr>
                ))}
              </TBody>
            </Table>
          ) : (
            <ErrorMessage>
              Sorry something went wrong, couldn&apos;t load items.
            </ErrorMessage>
          )}
        </>
      )}
    </Container>
  );
};

export default SubBrandTable;
